@charset "utf-8";

// Define defaults for each variable.

$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  13px !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       $section-headings-color !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

// Width of the content area
//$content-width:    800px !default;
$navbar-width:    $large-breakpoint !default;

$on-palm:          38em !default;
$on-laptop:        $large-breakpoint !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: calc($spacing-unit / 2);
//     padding-left: calc($spacing-unit / 2);
//   }
// }

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "blog/base",
  "blog/layout",
  "blog/extra"
;
