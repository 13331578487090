// Breakpoints
$large-breakpoint: 70em !default;
$medium-breakpoint: 42em !default;

// Headers
$header-heading-color: #fff !default;
$header-bg-color: #159957 !default;
$header-bg-color-secondary: #155799 !default;

// Text
$section-headings-color: #6c9494 !default; // #999999 #159957
$body-text-color: #24292e !default; // 606c71
$body-link-color: #1e6bb8 !default;
$blockquote-text-color: #6a737d !default; // 819198

// Code
$code-bg-color: #f3f6fa !default;
$code-text-color: #567482 !default;

// Borders
$border-color: #dce6f0 !default;
$table-border-color: #e9ebec !default;
$hr-border-color: #eff0f1 !default;
